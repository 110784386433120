<template>
  <div id="C">
    <h1 class="brand-color-dark">Verfügbare Dienste</h1>
    <h4>Alle Dienste, die von anderen Nutzern zum Tausch angeboten werden.</h4>

    <NoDataBox v-if="exchanges.length == 0" headline="Keine Tauschangebote verfügbar"
      >Es werden zurzeit keine Dienste angeboten.<br />
      Öffne Deinen <router-link class="link" to="../dienstplan/monatsplan">Dienstplan</router-link>, um einen Dienst zum Tausch
      auszuwählen.</NoDataBox
    >

    <!-- <div class="grid grid-cols-3 gap-2"> -->

      <!-- <div class="col-span-2 grid grid-cols-2 gap-4"> -->

    <div class="grid grid-cols-2 gap-4">
      <ExchangeItem  v-for="exchange in exchanges" :key="exchange.id" :exchange="exchange"></ExchangeItem>
    </div>


    <!-- <div class="h-full rounded-md bg-black text-white p-2 border-blue-800">
      <h4 class="text-lg font-bold brand-color-dark">Filterung</h4>

        <div class="mb-3">
          <p><b>Linie</b> <small>(0 gewählt)</small></p>
          <div class="flex gap-3 flex-wrap">
            <div v-for="line in lines" :key="line.id">
              <label class="checkbox-container">
                <input type="checkbox" :value="line.id" />
                <span class="checkmark"></span> {{line.name}}
              </label>
            </div>
          </div>
        </div>

        <div class="w-full mb-3">
          <p><b>Dienstlage</b> <small>(0 gewählt)</small></p>
          <ul>
            <li v-for="dutyTime in dutyTimes" :key="dutyTime.id">
              <label class="checkbox-container">
                <input type="checkbox" :value="dutyTime.id" />
                <span class="checkmark"></span> <ServiceSituationIcon :icon="dutyTime.typ"></ServiceSituationIcon> {{dutyTime.name}} ({{dutyTime.time_start}} - {{dutyTime.time_end}} Uhr)
              </label>
            </li>
          </ul>
        </div>

        <div class="flex mb-3 gap-4">
          <div>
            <p><b>Dienstbeginn</b> <small>(0 gewählt)</small></p>
            <p><font-awesome-icon icon="caret-up" class="icon" /> <b>Betriebshof</b></p>
            <ul >
              <li v-for="station in stations" :key="station.id" v-show="station.depot">
                <label ><input type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
              </li>
            </ul>
            <p><font-awesome-icon icon="caret-up" class="icon" /> <b>Haltestelle</b></p>
            <ul >
              <li v-for="station in stations" :key="station.id" v-show="!station.depot && station.change_location">
                <label><input type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
              </li>
            </ul>
          </div>

          <div>
            <p><b>Dienstende</b> <small>(0 gewählt)</small></p>
            <p><font-awesome-icon icon="caret-up" class="icon" /> <b>Betriebshof</b></p>
            <ul >
              <li v-for="station in stations" :key="station.id" v-show="station.depot">
                <label ><input type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
              </li>
            </ul>
            <p><font-awesome-icon icon="caret-up" class="icon" /> <b>Haltestelle</b></p>
            <ul >
              <li v-for="station in stations" :key="station.id" v-show="!station.depot && station.change_location">
                <label><input type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="">
          <p><b>Anforderungen</b> <small>(0 gewählt)</small></p>
          <ul>
            <li v-for="s in specialRequirements" :key="s.id">
              <label class="checkbox-container">
                <input type="checkbox" :value="s.id" />
                <span class="checkmark"></span> {{s.name}}
              </label>
            </li>
          </ul>
        </div>
    </div> -->

    <div class="text-center" style="margin-top: 50px">
      <small
        >Insgesamt
        {{
          exchanges && exchanges.length ? exchanges.length : 0
        }}
        Dienste</small
      >
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ExchangeItem from "@/components/ExchangeItem";

export default {
  name: "C",
  components: {
    NoDataBox,
    ServiceSituationIcon,
    ShiftDetails,
    ExchangeItem
  },
  data() {
    return {
      lines: [],
      stations: [],
      dutyTimes: [],
      specialRequirements: [],
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchExchanges");

    this.$store.dispatch("fetchLines").then((a) => {
      if (a.status == true) {
        this.lines = a.response
      }
    });

    this.$store.dispatch("fetchStations",{
      "_or": [
        {
          "depot": {
            "_eq": true
          }
        },
        {
          "change_location": {
            "_eq": true
          }
        }
      ]
    }).then((a) => {

      if (a.status == true) {
        this.stations = a.response
      }
    });

    this.$store.dispatch("fetchDutyTimes").then((a) => {
      if (a.status == true) {
        this.dutyTimes = a.response
      }
    });

    this.$store.dispatch("fetchSpecialRequirements").then((a) => {
      if (a.status == true) {
        this.specialRequirements = a.response
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exchanges: "getExchanges",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon",
    }),
  },
};
</script>